// スマホサイズでサイドバーを表示した際に、サイドバーが表示される領域と重なっている部分が反応するように
// サイドバー表示時にclass属性を追加/追加されていれば除去
document.addEventListener('DOMContentLoaded', () => {
  const sidebar = document.querySelector('.fixed-sidebar');
  const sidebarToggle = document.querySelector('.nav-link');

  sidebarToggle.addEventListener('click', () => {
    sidebar.classList.toggle('open');
  });
});
